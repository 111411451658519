import React from "react";
import ContactForm from "../ContactFrom/ContactForm";
import icon1 from "../../images/icons/icon_map_mark_2.svg";
import icon2 from "../../images/icons/icon_calling_2.svg";
import icon3 from "../../images/icons/icon_mail_3.svg";
import icon4 from "../../images/icons/icon_calendar_2.svg";

const ContactSection = (props) => {
  return (
    <section className="contact_section section_space bg-light">
      <div className="container">
        <div className="contact_info_box row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon1} alt="Map Mark SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Location</h3>
                <p className="mb-0">Rivonia Johannesburg, SA</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon2} alt="Calling SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Contact</h3>
                <p className="mb-0">(+27) 61 436 1548</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon3} alt="User Check SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Email</h3>
                <p className="mb-0">info@eragateway.com</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon4} alt="Calendar SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Visit Between</h3>
                <p className="mb-0">Mon - Sat: 8.00-5.00</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section_space pb-10">
          <div className="row justify-content-lg-between">
            <div className="col-lg-7">
              <div className="contact_form mb-0">
                <h3 className="details_item_info_title mb-1">
                  Send Us A Message
                </h3>
                <p className="mb-5">
                  Give us chance to serve and bring magic to your brand.
                </p>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
