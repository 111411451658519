import React, { Fragment } from "react";
import Header from "../../components/header/Header";
import { Link, useParams } from "react-router-dom";
import Teams from "../../api/team";
import CountUp from "react-countup";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import sImg1 from "../../images/icons/icon_facebook.svg";
import sImg2 from "../../images/icons/icon_twitter_x.svg";
import sImg3 from "../../images/icons/icon_linkedin.svg";
import sImg4 from "../../images/icons/icon_instagram.svg";

const TeamSinglePage = () => {
  const { slug } = useParams(); // Get the slug from the URL

  // Find the team member by slug
  const teamMember = Teams.find((item) => item.slug === slug);
  if (!teamMember) {
    return <div>Team member not found.</div>; // Handle case where team member is not found
  }

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <PageTitle
          pageTitle={teamMember.name}
          pagesub={"Details 😍"}
          pageTop={"Team Member"}
        />
        <section
          className="team_details_section section_space bg-light"
          style={{ paddingBottom: "50px" }}
        >
          <div className="container">
            <div className="team_member_details_card">
              <div className="team_member_image">
                <img src={teamMember.tImg} alt="Team Member" />
              </div>
              <div className="team_member_content">
                <h2 className="team_member_name">{teamMember.name}</h2>
                <p style={{ lineHeight: "0px", color: "lightgray" }}>
                  {teamMember.slug}
                </p>
                <ul className="icon_list unordered_list_block">
                  <li>
                    <span className="icon_list_text">
                      <strong>Role:</strong> {teamMember.title}
                    </span>
                  </li>
                </ul>
                {teamMember.Id === 1 || teamMember.Id === 2 ? (
                  <div className="social_wrapper">
                    <h3 className="social_title">Social Media</h3>
                    <ul className="social_icons_block unordered_list">
                      <li>
                        <Link
                          onClick={ClickHandler}
                          to={teamMember.socialLinks.facebook}
                          target="_blank"
                        >
                          <img src={sImg1} alt="Icon Facebook" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={ClickHandler}
                          to={teamMember.socialLinks.twitter}
                          target="_blank"
                        >
                          <img src={sImg2} alt="Icon Twitter X" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={ClickHandler}
                          to={teamMember.socialLinks.linkedIn}
                          target="_blank"
                        >
                          <img src={sImg3} alt="Icon Linkedin" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={ClickHandler}
                          to={teamMember.socialLinks.instagram}
                          target="_blank"
                        >
                          <img src={sImg4} alt="Icon Instagram" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
            <h3 className="details_item_info_title">Professional Skills</h3>
            <p>{teamMember.professionalSkills}</p>{" "}
            {/* Use dynamic skills description */}
            <div className="row mb-5">
              {/* Counter components can remain as they are or be made dynamic */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="funfact_block text-center">
                  <div className="counter_value">
                    <span className="odometer" data-count="98">
                      <CountUp end={teamMember.countUps[0]} enableScrollSpy />
                    </span>
                    <span>%</span>
                  </div>
                  <h3 className="funfact_title mb-0">
                    {teamMember.profSkills[0]}
                  </h3>
                  <div className="bottom_line bg-primary"></div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="funfact_block text-center">
                  <div className="counter_value">
                    <span className="odometer" data-count="98">
                      <CountUp end={teamMember.countUps[1]} enableScrollSpy />
                    </span>
                    <span>%</span>
                  </div>
                  <h3 className="funfact_title mb-0">
                    {teamMember.profSkills[1]}
                  </h3>
                  <div className="bottom_line bg-primary"></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="funfact_block text-center">
                  <div className="counter_value">
                    <span className="odometer" data-count="98">
                      <CountUp end={teamMember.countUps[2]} enableScrollSpy />
                    </span>
                    <span>%</span>
                  </div>
                  <h3 className="funfact_title mb-0">
                    {teamMember.profSkills[2]}
                  </h3>
                  <div className="bottom_line bg-primary"></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="funfact_block text-center">
                  <div className="counter_value">
                    <span className="odometer" data-count="98">
                      <CountUp end={teamMember.countUps[3]} enableScrollSpy />
                    </span>
                    <span>%</span>
                  </div>
                  <h3 className="funfact_title mb-0">
                    {teamMember.profSkills[3]}
                  </h3>
                  <div className="bottom_line bg-primary"></div>
                </div>
              </div>
              {/* Add other counters similarly... */}
            </div>
            <h3 className="details_item_info_title">Education</h3>
            <p className="mb-2">Qualifications:</p>
            <ul
              className="icon_list unordered_list_block"
              style={{ marginLeft: "20px" }}
            >
              {teamMember.qualifications.map((qual, index) => (
                <li key={index}>
                  <span className="icon_list_icon">
                    <i className="fa-solid fa-circle fa-fw"></i>
                  </span>
                  <span className="icon_list_text">{qual}</span>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <CtaSection />
      </main>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default TeamSinglePage;
