import React, { useState } from "react";
import Bg from "../../../images/shapes/shape_title_under_line.svg";
import shape1 from "../../../images/shapes/shape_line_2.svg";
import shape2 from "../../../images/shapes/shape_line_3.svg";
import shape3 from "../../../images/shapes/shape_line_4.svg";
import shape4 from "../../../images/shapes/shape_space_3.svg";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import TechnologySection from "../TechnologySection/TechnologySection";

const ProcessTechnology = (props) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <section className="process_technology_review_section bg-light section_decoration">
      <div className="container">
        <div className="row align-items-center justify-content-lg-between">
          <div className="col-lg-6">
            <div className="heading_block">
              <div
                className="heading_focus_text has_underline d-inline-flex"
                style={{ backgroundImage: `url(${Bg})` }}
              >
                Working Process
              </div>
              <h2 className="heading_text mb-0">
                Our <mark>Approach</mark>
              </h2>
            </div>
            <Accordion
              open={open}
              toggle={toggle}
              className="accordion"
              id="service_process_faq"
            >
              <AccordionItem className="accordion-item">
                <AccordionHeader targetId="1">
                  01. Discovery Phase
                </AccordionHeader>
                <AccordionBody accordionId="1" className="acc_body">
                  <p className="m-0">
                    The Discovery Phase lays the groundwork for our project by
                    understanding your business goals and gathering key
                    insights. Through research and workshops, we identify
                    requirements, analyze trends, and create a strategic roadmap
                    for successful execution.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className="accordion-item">
                <AccordionHeader targetId="2">
                  02. Design and Development
                </AccordionHeader>
                <AccordionBody accordionId="2" className="acc_body">
                  <p className="m-0">
                    The Design and Development phase transforms ideas into
                    reality. We craft intuitive designs and develop robust
                    solutions that align with your vision. This stage ensures a
                    seamless blend of creativity and technical expertise to
                    build a product that is functional, scalable, and
                    user-centric.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className="accordion-item">
                <AccordionHeader targetId="3">03. Maintenance</AccordionHeader>
                <AccordionBody accordionId="3" className="acc_body">
                  <p className="m-0">
                    The Maintenance phase ensures your solution stays reliable
                    and up-to-date. We provide continuous support, implement
                    updates, and monitor performance to address issues
                    proactively and maintain peak functionality.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className="accordion-item">
                <AccordionHeader targetId="4">04. Deployment</AccordionHeader>
                <AccordionBody accordionId="4" className="acc_body">
                  <p className="m-0">
                    The Deployment phase marks the launch of your solution into
                    the live environment. We ensure a smooth rollout with
                    thorough testing, configuration, and monitoring,
                    guaranteeing a seamless transition and optimal performance
                    from day one.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className="accordion-item">
                <AccordionHeader targetId="5">
                  05. Testing and QA
                </AccordionHeader>
                <AccordionBody accordionId="5" className="acc_body">
                  <p className="m-0">
                    The Testing and QA phase ensures your solution meets the
                    highest quality standards. We conduct rigorous testing to
                    identify and resolve any issues, ensuring reliability,
                    performance, and a flawless user experience.
                  </p>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="col-lg-5 hidden">
            <Accordion
              open={open}
              toggle={toggle}
              className="accordion"
              id="service_process_faq"
            >
              <ul className="content_layer_group unordered_list_block text-center">
                <li>
                  <AccordionHeader targetId="1">
                    <span>Discovery Phase</span>
                  </AccordionHeader>
                </li>
                <li>
                  <AccordionHeader targetId="2">
                    <span>Design and Development</span>
                  </AccordionHeader>
                </li>
                <li>
                  <AccordionHeader targetId="3">
                    <span>Maintenance</span>
                  </AccordionHeader>
                </li>
                <li>
                  <AccordionHeader targetId="4">
                    <span>Deployment</span>
                  </AccordionHeader>
                </li>
                <li>
                  <AccordionHeader targetId="5">
                    <span>Testing and QA</span>
                  </AccordionHeader>
                </li>
              </ul>
            </Accordion>
          </div>
        </div>
        <TechnologySection />
      </div>

      <div className="decoration_item shape_image_1">
        <img src={shape1} alt="Eragateway Shape" />
      </div>
      <div className="decoration_item shape_image_2">
        <img src={shape2} alt="Eragateway Shape" />
      </div>
      <div className="decoration_item shape_image_3">
        <img src={shape3} alt="Eragateway Shape" />
      </div>
      <div className="decoration_item shape_image_4">
        <img src={shape4} alt="Eragateway Shape" />
      </div>
    </section>
  );
};
export default ProcessTechnology;
