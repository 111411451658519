import React from "react";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import "../sass/nopage.scss";
export default function PageNotFound() {
  return (
    <>
      <Header />
      <main>
        <div className="not-found-page">
          <p className="err-404">404</p>
          <p className="error-msg-1">Uh Oh that page does not exist!</p>
          <a className="btn" href="/">
            <span className="btn_label" data-text="Back to home">
              Back to home
            </span>
            <span className="btn_icon">
              <i className="fa-solid fa-arrow-up-right"></i>
            </span>
          </a>
        </div>
      </main>
      <Footer />
    </>
  );
}
