import React from "react";
import sIcon1 from "../../images/icons/icon_clock.svg";
import sIcon2 from "../../images/icons/icon_dart_board_2.svg";
import sIcon3 from "../../images/icons/icon_target.svg";

const Policy = [
  {
    title: "Our History",
    subTitle:
      "The company, founded in 2019 by Peace Munyai, with a strong dedication to innovation and a focus on delivering high quality results.",
    icon: sIcon1,
  },
  {
    title: "Our Mission",
    subTitle:
      "Our mission is to deliver top notch systems and expectational customer service, we aim to be the forefront of technology and innovation.",
    icon: sIcon2,
  },
  {
    title: "Our Vision",
    subTitle:
      " We envision a future in which technology is effortlessly woven into daily life boosting productivity and encouraging growth. ",
    icon: sIcon3,
  },
];

const PolicySection = (props) => {
  return (
    <section className="policy_section bg-light">
      <div className="container">
        <div className="row">
          {Policy.map((policy, pitem) => (
            <div className="col-lg-4" key={pitem}>
              <div className="iconbox_block">
                <div className="iconbox_icon">
                  <img src={policy.icon} alt="Dollar SVG Icon" />
                </div>
                <div className="iconbox_content">
                  <h3 className="iconbox_title">{policy.title}</h3>
                  <p className="mb-0">{policy.subTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PolicySection;
