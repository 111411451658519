import { FaLaptopCode, FaMobileAlt, FaCode } from "react-icons/fa";
import { MdWeb, MdApi } from "react-icons/md";

const Services = [
  {
    Id: 1,
    sImg: <FaLaptopCode />,
    title: "IT Consulting",
    slug: "IT-Consulting",
    description: `Our IT consulting services empower businesses by providing strategic insights and tailored technical solutions. From seamless system integration to comprehensive digital transformation, we collaborate closely to address your unique challenges.`,
  },

  {
    Id: 2,
    sImg: <MdWeb />,
    title: "Web System Design",
    slug: "Web-System-Design",
    description: `We create robust, user-friendly web systems tailored to meet your business goals. Whether
      you need a secure e-commerce platform or a comprehensive enterprise solution, we ensure an
      optimal user experience and seamless functionality.`,
  },
  {
    Id: 3,
    sImg: <FaCode />,
    title: "Software Development & Maintenance",
    slug: "Software-Development-&-Maintenance",
    description: `Our team specializes in developing customized software solutions that drive efficiency and
      scalability. We provide continuous support and maintenance, ensuring your software remains
      up-to-date and fully operational as your business grows.
      `,
  },

  {
    Id: 4,
    sImg: <FaMobileAlt />,
    title: "Mobile App Development",
    slug: "Mobile-App-Development",
    description: `In today’s mobile-first world, we design intuitive, high-performance mobile applications that
      engage users and drive business growth. From concept to deployment, our mobile solutions
      are crafted to provide a seamless experience on any device.
      `,
  },

  {
    Id: 5,
    sImg: <MdApi />,
    title: "API Development",
    slug: "API-Development",
    description: `Our API development services enable seamless data connectivity and integration across your
      business applications. We build APIs that facilitate smooth communication between systems,
      enhancing functionality and boosting operational efficiency.`,
  },
];

export default Services;
