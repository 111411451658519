import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../main-component/HomePage/HomePage";
import AboutUsPage from "../main-component/AboutUsPage/AboutUsPage";
import TeamPage from "../main-component/TeamPage/TeamPage";
import TeamSinglePage from "../main-component/TeamSinglePage/TeamSinglePage";
import ServicePage from "../main-component/ServicePage/ServicePage";
import ServiceSinglePage from "../main-component/ServiceSinglePage/ServiceSinglePage";
import ContactPage from "../main-component/ContactPage/ContactPage";
import PageNotFound from "../components/pageNotFound";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="about" element={<AboutUsPage />} />

          <Route path="team" element={<TeamPage />} />
          <Route path="team-single/:slug" element={<TeamSinglePage />} />
          <Route path="service" element={<ServicePage />} />
          <Route path="service-single/:slug" element={<ServiceSinglePage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
