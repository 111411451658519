import React from "react";
import Bg from "../../../images/backgrounds/bg_image_2.webp";
import Bg2 from "../../../images/shapes/shape_bg_1.webp";
import Bg3 from "../../../images/shapes/shape_title_under_line.svg";
import about1 from "../../../images/about/6.png";
import aIcon1 from "../../../images/avatar/avatar_1.webp";
import aIcon2 from "../../../images/avatar/avatar_2.webp";
import aIcon3 from "../../../images/avatar/avatar_3.webp";
import aIcon4 from "../../../images/icons/icon_global.svg";
import aIcon5 from "../../../images/shapes/shape_line.webp";
import shape1 from "../../../images/shapes/shape_space_2.svg";

const About = (props) => {
  return (
    <section
      className="about_and_case_section section_space section_decoration bg-dark"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-lg-between">
          <div className="col-lg-7 order-lg-last">
            <div className="about_image_2">
              <div className="image_wrap">
                <img src={about1} alt="Eragateway - About" />
              </div>
              <div
                className="about_funfact_info"
                style={{ backgroundImage: `url(${Bg2})` }}
              >
                <div className="customer_count">
                  <ul className="unordered_list">
                    <li>
                      <img src={aIcon1} alt="Customer Avatar" />
                    </li>
                    <li>
                      <img src={aIcon2} alt="Customer Avatar" />
                    </li>
                    <li>
                      <img src={aIcon3} alt="Customer Avatar" />
                    </li>
                    <li>
                      <span>6k+</span>
                    </li>
                  </ul>
                  <p className="mb-0">Happy Customer</p>
                </div>
                <div className="about_funfact_counter">
                  <div className="funfact_item">
                    <div className="counter_value">
                      <span className="odometer" data-count="6">
                        0
                      </span>
                      <span>K+</span>
                    </div>
                    <h3 className="funfact_title mb-0">Projects Done</h3>
                  </div>
                  <div className="funfact_item">
                    <div className="counter_value">
                      <span className="odometer" data-count="100">
                        0
                      </span>
                      <span>%</span>
                    </div>
                    <h3 className="funfact_title mb-0">Results Guaranteed</h3>
                  </div>
                </div>
                <a className="btn btn-primary" href="about.html">
                  <span className="btn_label" data-text="Learn More">
                    Learn More
                  </span>
                  <span className="btn_icon">
                    <i className="fa-solid fa-arrow-up-right"></i>
                  </span>
                </a>
                <div className="icon_globe">
                  <img src={aIcon4} alt="Icon Globe" />
                </div>
              </div>
              <div className="space_line">
                <img src={aIcon5} alt="Shape Line" />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about_content">
              <div className="heading_block mb-0 text-white">
                <div
                  className="heading_focus_text has_underline d-inline-flex"
                  style={{ backgroundImage: `url(${Bg3})` }}
                >
                  About Us
                </div>
                <h2 className="heading_text">
                  Our <mark>Mission & Goal</mark>
                </h2>
                <p className="heading_description mb-0">
                  At Eragateway, our main asserts are varied, each team member
                  contributes their own specialized knowledge and common
                  enthusiasm for creativity, teamwork and high standards.
                  Collectively we provide outstanding technological solutions
                  that enable our clients to promote advancement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="decoration_item shape_image_1">
        <img src={shape1} alt="Techco Shape" />
      </div>
    </section>
  );
};

export default About;
