import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import Modal from "./Modal";

const ContactForm = () => {
  const [forms, setForms] = useState({
    fullname: "",
    clientEmail: "",
    clientPhone: "",
    message: "",
  });

  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState({ title: "", body: "" });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForms({ ...forms, [name]: value });

    // Validate on change
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      validator.hideMessages();

      try {
        const response = await fetch(
          process.env.REACT_APP_CLIENT_MESSAGES_API,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              fullname: forms.fullname,
              clientEmail: forms.clientEmail,
              clientPhone: forms.clientPhone,
              message: forms.message,
            }),
          }
        );

        if (response.ok) {
          setModalMessage({
            title: "Success",
            body: `Thank you for your enquiry, ${forms.fullname}. Our agents will get back to you shortly`,
          });
          setForms({
            fullname: "",
            clientEmail: "",
            clientPhone: "",
            message: "",
          });
        } else {
          setModalMessage({ title: "Error", body: "Error sending message" });
        }

        setModalOpen(true);
      } catch (err) {
        console.log(err);
        setModalMessage({ title: "Error", body: "Error sending message" });
        setModalOpen(true);
        // alert("Error sending message");
      }
    } else {
      validator.showMessages();
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className="row" style={{ paddingBottom: "100px" }}>
          <div className="col-md-6">
            <div className="form-group">
              <label className="input_title" htmlFor="input_name">
                Full Name
              </label>
              <input
                value={forms.fullname}
                type="text"
                name="fullname"
                className="form-control"
                onChange={changeHandler}
                placeholder="Your Name"
              />
              {validator.message(
                "fullname",
                forms.fullname,
                "required|alpha_space"
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="input_title" htmlFor="input_email">
                Your Email
              </label>
              <input
                value={forms.clientEmail}
                type="email"
                name="clientEmail"
                className="form-control"
                onChange={changeHandler}
                placeholder="email@example.com"
              />
              {validator.message(
                "clientEmail",
                forms.clientEmail,
                "required|email"
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="input_title" htmlFor="input_phone">
                Your Phone
              </label>
              <input
                value={forms.clientPhone}
                type="tel"
                name="clientPhone"
                className="form-control"
                onChange={changeHandler}
                placeholder="Enter Area Code"
              />
              {validator.message(
                "clientPhone",
                forms.clientPhone,
                "required|phone"
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="input_title" htmlFor="input_textarea">
                Message
              </label>
              <textarea
                onChange={changeHandler}
                value={forms.message}
                name="message"
                className="form-control"
                placeholder="How can we help you?"
              ></textarea>
              {validator.message("message", forms.message, "required")}
            </div>
            <button type="submit" className="btn btn-primary">
              <span className="btn_label" data-text="Send Message">
                Send Message
              </span>
              <span className="btn_icon">
                <i className="fa-solid fa-arrow-up-right"></i>
              </span>
            </button>
          </div>
        </div>
      </form>
      <Modal isOpen={isModalOpen} onClose={closeModal} message={modalMessage} />
    </>
  );
};

export default ContactForm;
