import React, { Fragment, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 3,
    title: "Company",
    link: "/portfolio",
    submenu: [
      {
        id: 345,
        title: "Team",
        link: "/team",
      },
      {
        id: 41,
        title: "Services",
        link: "/service",
      },
      {
        id: 42,
        title: "Services Details",
        link: "/service-single/IT-Management-Services",
      },
    ],
  },

  // {
  //   id: 5,
  //   title: "Portfolio",
  //   link: "/porfolio",
  //   submenu: [
  //     {
  //       id: 51,
  //       title: "Portfolio",
  //       link: "/porfolio",
  //     },
  //     {
  //       id: 52,
  //       title: "Portfolio Details",
  //       link: "/portfolio_details/Explore-Our-IT-Solutions",
  //     },
  //   ],
  // },
  {
    id: 322,
    title: "About Us",
    link: "/about",
  },
  {
    id: 88,
    title: "Contact",
    link: "/contact",
  },
];

const MobileMenu = () => {
  const [openId, setOpenId] = useState(0);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <ul className="clearfix">
      {menus.map((item, mn) => {
        return (
          <ListItem className={item.id === openId ? "active" : null} key={mn}>
            {item.submenu ? (
              <Fragment>
                <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>
                  {item.title}
                  <i
                    className={
                      item.id === openId ? "fa fa-angle-up" : "fa fa-angle-down"
                    }
                  ></i>
                </p>
                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                  <List className="subMenu">
                    <Fragment>
                      {item.submenu.map((submenu, i) => {
                        return (
                          <ListItem key={i}>
                            <Link
                              onClick={ClickHandler}
                              className="active"
                              to={submenu.link}
                            >
                              {submenu.title}
                            </Link>
                          </ListItem>
                        );
                      })}
                    </Fragment>
                  </List>
                </Collapse>
              </Fragment>
            ) : (
              <Link className="active" to={item.link}>
                {item.title}
              </Link>
            )}
          </ListItem>
        );
      })}
    </ul>
  );
};

export default MobileMenu;
